<template>
    <div>
        <div align="center">
            <h4 class="q-mb-sm">Fatture in attesa di emissione</h4>
            Estrae tutte le fatture in attesa di essere inviate all'Agenzia delle Entrate ma ancora modificabili
            <br><br>
            <hr>
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-4 q-mr-sm">
                <q-select dense outlined v-model="anno_creazione" :options="options_anno" label="Anno di creazione" />
                <br>
                <q-input dense outlined v-model="nominativo_cliente" label="Nominativo Contraente" />
                <br>             
            </div>
            <div class="col-12 col-md-4 row" align="center">
                <div class="col-12 col-md-12">
                    <q-input dense outlined v-model="numero_pratica" label="Numero pratica" />
                    <br>
                </div>
                <div class="col-12 col-md-6">
                    <q-input outlined
                        class="q-mb-sm"
                        v-model="dal_giorno"
                        mask="##/##/####"
                        dense
                        label="Creata dal giorno"
                    >
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="dal_giorno"
                                    mask="DD/MM/YYYY">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Close" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                            </q-icon>
                        </template>
                    </q-input>
                    <br>
                </div>
                <div class="col-12 col-md-6">
                    <q-input outlined
                        class="q-mb-sm"
                        v-model="al_giorno"
                        mask="##/##/####"
                        dense
                        label="Fino al giorno"
                    >
                        <template v-slot:append>
                            <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="al_giorno"
                                    mask="DD/MM/YYYY">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Close" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                            </q-icon>
                        </template>
                    </q-input>
                    <br>
                </div>
            </div>

            <div class="col-12 col-md-12" align="center">
                <br>
                <q-btn color="primary" label="Cerca" @click.native="onCercaFatture"/>
                <hr>
            </div>

        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="col-12 col-md-12">
            <q-table
                title=""
                :data="righe"
                :columns="colonne"
                color="primary"
                row-key="name"
                separator="cell"
                flat
                wrap-cells
                >

                <template v-slot:header="props">
                    <q-tr :props="props">
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                            style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                            <strong>{{ col.label }}</strong>
                        </q-th>
                    </q-tr>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props" @click.native="selezionaRecordSegnaposto(props.row)">
                        <q-td
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            <strong>{{ col.value }}</strong>
                        </q-td>
                    </q-tr>
                </template>
                
                <template v-slot:top-right>
                    <q-btn
                        color="primary"
                        icon-right="archive"
                        label="ESPORTA IN CSV"
                        no-caps
                        @click="esportaTabella"
                    />
                </template> 
            </q-table>
        </div>

        <div align="center">
            <hr>
            
            <div class="row justify-center">
                <div class="col-md-4" align="center">
                    <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                        @click.native="onIndietroClicked()"
                    />
                </div>
                
            </div>

            <br><br><br><br><br><br>
        </div>

        
    </div>
</template>


<script>

import { mapFields} from 'vuex-map-fields';
import { mapState,mapActions } from 'vuex';
import { exportFile } from 'quasar';
import QQButton from "@/components/QQButton.vue";

function wrapCsvValue (val, formatFn) {
    let formatted = formatFn !== void 0
        ? formatFn(val)
        : val

    formatted = formatted === void 0 || formatted === null
        ? ''
        : String(formatted)

    formatted = formatted.split('"').join('""')
    /**
     * Excel accepts \n and \r in strings, but some other CSV parsers do not
     * Uncomment the next two lines to escape new lines
     */
    // .split('\n').join('\\n')
    // .split('\r').join('\\r')

    return `"${formatted}"`
}

export default {
    name: "TabellaFattureNonEmesse",
    components: {
        QQButton
    },
    data() {
        return {
            is_loading: true,
            anno_creazione: "",
            options_anno: [],
            numero_pratica: "",
            nominativo_cliente: "",
            dal_giorno: "",
            al_giorno: ""
        }
    },
    computed: {
        ...mapState({
            //is_loading: state => state.gestionePratiche.is_loading
        }),
        ...mapFields("gestioneFatture", {
            colonne: "colonne",
            righe: "righe",
            idpratica: "idpratica_selezionata",
            idcontratto: "idcontratto_selezionato",
            pagina_sorgente: "pagina_sorgente"
        }),
    },
    methods: {
        ...mapActions({
            ElencoFattureNonEmesse: "gestioneFatture/ElencoFattureNonEmesse"
        }),
        esportaTabella() {
            // naive encoding to csv format
            const content = [ this.colonne.map(col => wrapCsvValue(col.label)) ].concat(
                this.righe.map(row => this.colonne.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                        ? col.field(row)
                        : row[col.field === void 0 ? col.name : col.field],
                    col.format
                )).join(','))
            ).join('\r\n')

            const status = exportFile(
                'Fatture_Attesa_Emissione.csv',
                content,
                'text/csv'
            )

            if (status !== true) {
                this.$q.notify({
                    message: 'Browser denied file download...',
                    color: 'negative',
                    icon: 'warning'
                })
            }
        },
        selezionaRecordSegnaposto(row) {
            this.idpratica = row.idpratica;
            this.idcontratto = row.idcontratto;

            this.$router.push({name : "Fatture.SchedaFattura"});
        },
        AggiustaFormatoDateForDb(data) {
            //console.log("data:",data);
            data = (data+"").substring(0,10);
            console.log("data:",data);
            var elementi = data.split("/");

            console.log("elementi for DB:",elementi);

           // if (commonLib.isEmpty(elementi[1])) return data;

            console.log("data modificata for DB:",elementi[2]+"-"+elementi[1].padStart(2,'0')+"-"+elementi[0].padStart(2,'0'));

            return elementi[2]+"-"+elementi[1].padStart(2,'0')+"-"+elementi[0].padStart(2,'0')+"T00:00:00";
        },
        onIndietroClicked() {
            this.$router.push({name : "Fatture"});
        },
        async onCercaFatture() {
            var dalGiorno = this.dal_giorno;
            var alGiorno  = this.al_giorno;

            if (dalGiorno === null) dalGiorno = "";
            if (alGiorno === null) alGiorno = "";

            if ((dalGiorno !== null) && (dalGiorno != "")) dalGiorno = this.AggiustaFormatoDateForDb(dalGiorno);
            if ((alGiorno !== null) && (alGiorno != "")) alGiorno = this.AggiustaFormatoDateForDb(alGiorno);
            

            await this.ElencoFattureNonEmesse({
                anno: this.anno_creazione,
                nominativo: this.nominativo_cliente,
                dalGiorno: dalGiorno,
                alGiorno: alGiorno,
                guidPratica: this.numero_pratica
            });
        }
    },
    async mounted() {
        this.is_loading = true;
        let oggi = new Date();
        this.anno_creazione = oggi.getFullYear();
        this.pagina_sorgente = "TabellaFattureNonEmesse";

        console.log("anno:",this.anno_creazione);

        await this.ElencoFattureNonEmesse({
            anno: this.anno_creazione,
            nominativo: "",
            dalGiorno: "",
            alGiorno: "",
            guidPratica: ""
        });

        this.options_anno = [
            { "label": this.anno_creazione, "value": this.anno_creazione },
            { "label": parseInt(this.anno_creazione-1), "value": parseInt(this.anno_creazione-1) },
            { "label": parseInt(this.anno_creazione-2), "value": parseInt(this.anno_creazione-2) }
        ];

    }
}
</script>

<style >

   td {
       cursor: pointer
    }

</style>


